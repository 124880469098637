import { useMemo } from "react";

import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";

import { OverviewStats } from "../OverviewStats/OverviewStats";
import { REPORT_TYPES } from "../../constants/dashboards";
import { ReportsSelectors } from "../../redux";
import {
  dpdUndeliveredDepotBreakdownColumns,
  dpdUndeliveredResults,
} from "../../constants/tables";
import { Table } from "../Table/Table";
import styles from "./DeliveryServiceUndelivered.module.scss";
import { BarChart } from "../BarChart/BarChart";
import withReport from "../../HOCs/withReport";
import FilterOptions from "../FilterOptions/FilterOptions";

const DeliveryServiceUndelivered = () => {
  const isPDFExported = useSelector(ReportsSelectors.getIsPDFExported);
  const {
    stats,
    undeliveredResults,
    undeliveredDailyView,
    undeliveredDepotBreakdown,
  } = useSelector(ReportsSelectors.getReportData);

  const overviewStats = useMemo(
    () => [
      {
        title: "Undelivered %",
        value: stats.totalUndeliveredParcelsPercent,
      },
      {
        title: "Reconsigned %",
        value: stats.totalReconsignedParcelsPercent,
      },
      {
        title: "Total Parcel Volume",
        value: stats.totalParcelVolume,
      },
      {
        title: "Total Undelivered Parcels",
        value: stats.totalUndeliveredParcels,
      },

      {
        title: "Total Reconsigned Parcels",
        value: stats.totalReconsigned,
      },
    ],
    [stats]
  );

  return (
    <>
      <FilterOptions report={REPORT_TYPES.PERISHABLE} />
      <div id="pdf">
        <Row className="mt-3">
          <OverviewStats stats={overviewStats} colSpan={4} />
          <Table
            colSpan={8}
            data={undeliveredResults}
            columns={dpdUndeliveredResults}
            title="Undelivered Results"
            className={!isPDFExported && styles.undeliveredResults}
          />
        </Row>
        <Row className="mt-3">
          <BarChart
            data={undeliveredDailyView}
            title="DPD Service - Daily View"
            labelFontSize="10px"
            orientation="vertical"
            showYAxis={false}
            colSpan={12}
          />
        </Row>
        <Row className="my-3">
          <Table
            colSpan={12}
            data={undeliveredDepotBreakdown}
            columns={dpdUndeliveredDepotBreakdownColumns}
            title="Delivery Depot"
            className={!isPDFExported && styles.depotBreakdown}
          />
        </Row>
      </div>
    </>
  );
};

export default withReport({ report: REPORT_TYPES.PERISHABLE })(
  DeliveryServiceUndelivered
);
