import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { Card } from "@dpdgroupuk/mydpd-ui";

import { DASHBOARDS } from "../../constants/dashboards";
import { Dropdown } from "../Dropdown/Dropdown";
import styles from "./Dashboard.module.scss";

export const DashboardMenu = ({ onChange }) => {
  return (
    <Row className="mt-3">
      <Col md={12}>
        <Card>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <Card.Title>Select a Customer Report</Card.Title>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <div className={styles["dashboard-menu"]}>
                <Dropdown
                  options={DASHBOARDS}
                  title="Dashboard"
                  onChange={onChange}
                  isMulti={false}
                  defaultValue={DASHBOARDS[0]}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

DashboardMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
};
