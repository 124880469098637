import { useState } from "react";

import { camelCase, isEmpty } from "lodash";
import { Col, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import { useAppUser, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { Button, Card, useOverlay, withOverlay } from "@dpdgroupuk/mydpd-ui";

import { Dropdown } from "../Dropdown/Dropdown";
import { deepObjectClone } from "../../utils/arr";
import { shallowEqual } from "../../utils/obj";
import { ReportsActions, ReportsSelectors } from "../../redux";
import DownloadBtn from "../DownloadBtn/DownloadBtn";
import { FILTERS } from "../../constants/tables";

const FilterOptions = ({ fetchReportsData, report }) => {
  const [filters, setFilters] = useState({});
  const [prevFilters, setPrevFilters] = useState({});
  const filterOptions = useSelector(ReportsSelectors.getFilterOptions);
  const { customername } = useSelector(state => state.app.ums.customer);
  const { customerGroupName } = useSelector(ReportsSelectors.getReportData);
  const { accounts } = useAppUser();
  const overlay = useOverlay();
  const customerName = !isEmpty(customerGroupName)
    ? customerGroupName
    : customername;

  const handleOnFilterUpdateClick = async () => {
    if (shallowEqual(filters, prevFilters)) {
      return;
    }

    try {
      overlay.show();

      const data = {
        dpdAccounts: accounts,
        ...(filters && { ...filters }),
      };

      await fetchReportsData(report, data);

      setPrevFilters(filters);
    } catch (error) {
      overlay.hide();
    }

    overlay.hide();
  };

  const handleOnChange = async (selectedOption, { name }) => {
    const currentFilters = deepObjectClone(filters);

    currentFilters[camelCase(name)] = selectedOption.map(({ value }) => value);
    setFilters(currentFilters);
  };

  const getDisabledState = title => {
    // Only 1 exception filter can be applied at a time so disable other exception filters
    switch (title) {
      case FILTERS.DPD_EXCEPTION:
        return (
          !isEmpty(filters.customerException) ||
          !isEmpty(filters.recipientException)
        );
      case FILTERS.CUSTOMER_EXCEPTION:
        return (
          !isEmpty(filters.dpdException) || !isEmpty(filters.recipientException)
        );
      case FILTERS.RECIPIENT_EXCEPTION:
        return (
          !isEmpty(filters.dpdException) || !isEmpty(filters.customerException)
        );
      default:
        return false;
    }
  };

  return (
    <Row className="mt-3">
      <Col md={12}>
        <Card>
          <Row>
            <Col md={9}>
              <Card.Title>
                {customerName} UK Delivery Service - Last 4 Weeks
              </Card.Title>
            </Col>
            <Col
              md={3}
              className="d-flex justify-content-end align-items-center"
            >
              <Button
                className="mr-3"
                variant="danger"
                onClick={handleOnFilterUpdateClick}
              >
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => window.location.reload()}
              >
                Reset
              </Button>
              <DownloadBtn />
            </Col>
          </Row>
          {filterOptions && (
            <>
              {filterOptions.map((row, idx) => (
                <Row key={idx}>
                  {row.map(({ options, title }) => (
                    <Col key={title} md={3} className="mt-3">
                      <Dropdown
                        options={options}
                        title={title}
                        onChange={handleOnChange}
                        isDisabled={getDisabledState(title)}
                      />
                    </Col>
                  ))}
                </Row>
              ))}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

FilterOptions.propTypes = {
  fetchReportsData: PropTypes.func,
  report: PropTypes.string,
};

export default compose(
  withAppUserPreferences,
  withOverlay,
  connect(null, dispatch => ({
    fetchReportsData: (reportType, params) =>
      dispatch(ReportsActions.fetchReportsData(reportType, params)),
  }))
)(FilterOptions);
