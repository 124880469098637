import { useEffect, useState } from "react";

import { Container } from "react-bootstrap";

import { useAppUser } from "@dpdgroupuk/mydpd-app";
import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";

import { ACCOUNT_TYPES, DASHBOARDS } from "../constants/dashboards";
import { DashboardMenu } from "../components/DashboardMenu/DashboardMenu";
import DeliveryServiceSummary from "../components/DeliveryServiceSummary/DeliveryServiceSummary";
import DeliveryServiceUndelivered from "../components/DeliveryServiceUndelivered/DeliveryServiceUndelivered";
import { NoAccess } from "../components/NoAccess/NoAccess";
import { useSelector } from "react-redux";
import { UmsSelectors } from "../redux";

const Reports = () => {
  const [selectedReport, setSelectedReport] = useState();
  const { priorityAccount } = useSelector(UmsSelectors.getCustomer);
  const { actionDatesLimitType } = useSelector(UmsSelectors.getCustomerPrefs);
  const { appRoles } = useAppUser();

  const hasReportAccess =
    appRoles.includes(APP_ROLES.REPORTS) && priorityAccount;

  const perishableAccess =
    actionDatesLimitType === ACCOUNT_TYPES.PERISHABLE ||
    actionDatesLimitType === ACCOUNT_TYPES.PERISHABLE_RETURNS;

  useEffect(() => {
    setSelectedReport(DASHBOARDS[0].label);
  }, []);

  const handleOnChange = async ({ value }) => {
    setSelectedReport(value);
  };

  return (
    <>
      {hasReportAccess ? (
        <Container>
          {perishableAccess ? (
            <DashboardMenu onChange={handleOnChange} />
          ) : (
            <div className="mt-3" />
          )}
          {selectedReport === DASHBOARDS[0].label ? (
            <DeliveryServiceSummary />
          ) : selectedReport === DASHBOARDS[1].label && perishableAccess ? (
            <DeliveryServiceUndelivered />
          ) : (
            <></>
          )}
        </Container>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Reports;
