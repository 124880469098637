export const DASHBOARDS = [
  {
    label: "Delivery Service Summary",
    value: "Delivery Service Summary",
  },
  {
    label: "Delivery Service Undelivered Parcels",
    value: "Delivery Service Undelivered Parcels",
  },
];

export const REPORT_TYPES = {
  VERY_IMPORTANT_ACCOUNT: "via",
  PERISHABLE: "per",
};

export const ACCOUNT_TYPES = {
  PERISHABLE: "PER",
  PERISHABLE_RETURNS: "PRT",
};
